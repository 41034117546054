.image_off,
#linkedin-icon:hover .image_on,
#medium-icon:hover .image_on,
#discord-icon:hover .image_on,
#telegram-icon:hover .image_on,
#twitter-icon:hover .image_on {
  display: none;
}

.image_on,
#linkedin-icon:hover .image_off,
#medium-icon:hover .image_off,
#discord-icon:hover .image_off,
#telegram-icon:hover .image_off,
#twitter-icon:hover .image_off {
  display: block;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

@media (max-width: 500px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
}
