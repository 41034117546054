.inputField {
  border-top: 2px solid #7e32de;
  border-bottom: 2px solid #2f82a7;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 20rem;
  padding-left: 5px;
  border-left: 2px solid #7f32dea6;
  border-right: 2px solid #2f82a7;
  background-color: transparent;
  color: white;
}

input::placeholder {
  font-size: 12px;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.56);
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 250px auto;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 1rem 15px;
}

@media (max-width:500px) {
  .input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
  }

  .btn {
    padding: 0.6rem 15px;
  }
}
