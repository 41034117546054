.header {
    overflow: hidden;
}

.slider ul {
    width: 100%;
    transition: 0.5s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider ul li {
    margin-block: 0.5rem;
    margin-inline: 2rem;
    list-style: none;
}

.slider ul li div {
    text-decoration: none
}

.menu-icon .menu {
    display: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.closed {
    display: none;
    cursor: pointer;
    color: white;
    justify-content: flex-start;
    width: 100%;
    
}

.closed .close {
    font-size: 100px;
    color: white;
    margin: 5px 15px;
}

@media (max-width:500px) {
    .slider {
        position: fixed;
        min-width: 100%;
        height: 30vh;
        top: -100%;
        background-color: #1B1830;
        transition: 0.5s ease;
    }
    
    .slider.active {
        top: 0;
        transition: 0.5s ease;
    }
    
    .slider ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .slider ul li {
        margin-block: 1.5rem;
        list-style: none;
    }
    
    .slider ul li div {
        text-decoration: none;
    }
    
    .menu-icon .menu {
        display: block;
        color: white;
        font-size: 30px;
        cursor: pointer;
        margin: 5px 10px;
    }
    
    .closed {
        display: block;
        cursor: pointer;
        color: white;
        justify-content: flex-start;
        width: 100%;
    }
    
    .closed .close {
        font-size: 30px;
        color: white;
        margin: 5px 15px;
    }
}